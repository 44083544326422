import App from '@/app/App.vue'
import router from '@/router/router'
import pinia from '@/stores/pinia/pinia'

import { createHead } from '@unhead/vue'
import FloatingVue from 'floating-vue'
import { createNotivue } from 'notivue'

import { type Component, createApp } from 'vue'

import '@/plugins/plugin'

import '@vuepic/vue-datepicker/dist/main.css'

import '@/assets/fonts/inter.css'
import '@/assets/css/main.css'

const head = createHead()

const notivue = createNotivue({
    position: 'top-center',
    limit: 4,
    enqueue: true,
    notifications: {
        global: {
            duration: 6000,
        },
        warning: {
            duration: 10000,
        },
        error: {
            duration: 10000,
        },
    },
})

const app = createApp(App as Component)

app.use(head)

app.use(router)

app.use(FloatingVue)

app.use(pinia)

app.use(notivue)

app.mount('#app')
