/* eslint-disable ts/no-unsafe-argument */
/* eslint-disable ts/strict-boolean-expressions */
/* eslint-disable ts/no-unsafe-assignment */
/* eslint-disable ts/no-unsafe-call */
import checkAuthMiddleware from '@/router/middleware/checkAuthMiddleware'
import routes from '@/router/routes'
import NProgress from 'nprogress'
/* eslint-disable ts/no-unsafe-return */
import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized, type Router } from 'vue-router'

export interface RouterContext {
    to: RouteLocationNormalized
    from: RouteLocationNormalized
    next: NavigationGuardNext
    router: Router
}

const router = createRouter({
    routes,
    history: createWebHistory(),
})

router.beforeEach(async (to, from, next) => {
    // Start the loading bar.
    if (to.path !== from.path)
        NProgress.start()

    try {
        const matchedComponents = to.matched.flatMap((record) => {
            if (record.components) {
                return Object.values(record.components)
            }

            return []
        })

        // Get the matched components and resolve them.
        await resolveComponents(matchedComponents)
    }
    catch (error) {
        next(false)

        if (import.meta.env.PROD) {
            window.location.replace(to.fullPath)
        }

        console.error(error)

        return
    }

    if (to.matched.some(record => record.meta.middleware)) {
        const middlewareSet = new Set([checkAuthMiddleware])

        to.matched.filter(item => item.meta.middleware).forEach((item) => {
                const middlewareArray = Array.isArray(item.meta.middleware) ? item.meta.middleware : [item.meta.middleware]

                middlewareArray.forEach(item => middlewareSet.add(item))
            })

        const middleware = Array.from(middlewareSet)

        const context = <RouterContext>{
            to,
            from,
            next,
            router,
        }

        return middleware[0]({
            ...context,
            next: nextFactory(context, middleware),
        })
    }

    return next()
})

router.afterEach(() => {
    NProgress.done()
})

window.router = router

export default router

async function resolveComponents(components: any[]) {
    return Promise.all(
        components.map((component) => {
            return typeof component === 'function' ? component() : component
        }),
    )
}

function nextFactory(context: RouterContext, middleware: any[], index = 1) {
    const subsequentMiddleware = middleware[index]

    // If no subsequent Middleware exists, the default `next()` callback is returned.
    if (!subsequentMiddleware)
        return context.next

    return () => {
        // Then run the subsequent Middleware with a new `nextMiddleware()` callback.
        subsequentMiddleware({
            ...context,
            next: nextFactory(context, middleware, index + 1),
        })
    }
}
