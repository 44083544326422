import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { PiniaSharedState } from 'pinia-shared-state'

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

pinia.use(PiniaSharedState({ enable: false }))

export default pinia
