function getInitialState(): Dialog.InitialDialogState {
    return {
        title: undefined,
        text: undefined,
        allowOutsideClick: false,
        confirmText: 'Aceptar',
        denyText: undefined,
        cancelText: 'Cancelar',
        confirmColor: 'green',
        denyColor: undefined,
        onConfirm: undefined,
        onDeny: undefined,
        onCancel: undefined,
        noCancelButton: false,
    } satisfies Dialog.InitialDialogState
}

export const useDialogStore = defineStore('dialog', () => {
    const isOpen = ref(false)

    const dialogState = ref(getInitialState())

    function resetDialog() {
        dialogState.value = getInitialState()
    }

    function showDialog(newDialogState: Dialog.DialogState) {
        resetDialog()

        Object.assign(dialogState.value, newDialogState)

        isOpen.value = true
    }

    function closeDialog(callBack?: () => void) {
        if (callBack && typeof callBack === 'function') {
            callBack()
        }

        isOpen.value = false
    }

    return { isOpen, dialogState, showDialog, closeDialog, resetDialog }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDialogStore, import.meta.hot))
}
