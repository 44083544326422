import type { RouterContext } from '../router'

export default ({ next }: RouterContext) => {
    const authStore = useAuthStore()

    if (authStore.admin) {
        return next()
    }

    return next({ name: 'Dashboard' })
}
